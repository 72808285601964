/* @font-face {
  font-family: "ProximaNova-Bold";
  src: url("/fonts/ProximaNova-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova-BoldIt";
  src: url("/fonts/ProximaNova-BoldIt.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova-Extrabld";
  src: url("/fonts/ProximaNova-Extrabld.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova-Regular";
  src: url("/fonts/ProximaNova-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova-Black";
  src: url("/fonts/ProximaNova-Black.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova-RegularIt";
  src: url("/fonts/ProximaNova-RegularIt.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "soehne-web";
  src: url("/fonts/soehne-web-buch.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "soehne-web";
  src: url("/fonts/soehne-web-halbfett.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "soehne-web";
  src: url("/fonts/soehne-web-halbfett-kursiv.woff2") format("woff2");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "soehne-web";
  src: url("/fonts/soehne-web-buch-kursiv.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  /* font-family: "soehne-web", sans-serif; */
  font-weight: 400;
  color: theme("colors.black");
  scroll-behavior: smooth;
  font-size: 16px;
}

html,
body {
  @apply h-full;
}

body > div:first-child {
  @apply h-full;
}
.logo {
  display: block;
  width: 270px;
  height: 25px;
  background: url("/images/cc-logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 1023px) {
  .logo {
    width: 200px;
    height: 15px;
  }
}
.skip-link {
  @apply absolute z-[10] top-[0] left-[50%] translate-x-[-50%] translate-y-[-140%] z-[10] transition-all bg-primary text-white text-sm lg:text-base whitespace-nowrap px-6 py-3 rounded-[100px];
}
.skip-link:focus {
  @apply translate-x-[-50%] translate-y-[10%];
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.main-nav div:nth-child(1),
.main-nav div:nth-child(2) {
  order: 1;
}
.main-nav div:nth-child(3),
.main-nav div:nth-child(4) {
  order: 3;
}
.main-nav div:nth-child(5) {
  order: 2;
}

textarea:focus,
input:focus {
  outline: none;
}

.custom-checkbox-label {
  @apply leading-none before:inline-block before:align-text-bottom before:mr-1 before:lg:mr-3 before:w-[20px] before:h-[20px] before:border before:rounded-[4px] before:border-black/[0.5] before:lg:border-white;
}

.custom-checkbox:checked + .custom-checkbox-label {
  @apply after:absolute after:top-[3px] after:lg:top-[9px] after:left-[5px] after:lg:left-[5px] after:w-[10px] after:h-[10px] after:bg-secondary after:rounded-[2px];
}

mark {
  @apply bg-secondary/[0.8];
}

.dato-image-contain,
.dato-image-cover {
  height: 100%;
  max-width: 100% !important;
}
.dato-image-contain img,
.dato-image-cover img {
  margin: 0;
}
.dato-image-contain picture,
.dato-image-cover picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.dato-image-cover picture img {
  object-fit: cover;
  object-position: center;
  margin: 0;
}
.dato-image-contain picture img {
  object-fit: contain;
  object-position: center;
  margin: 0;
}
